import axios from "axios";

export const getUserDetails = async (estoreid, resellid, authToken) =>
  await axios.get(
    process.env.REACT_APP_API + "/gratis/user-details/" + resellid,
    {
      headers: {
        authToken,
        estoreid,
      },
    }
  );

export const getRaffleEntries = async (estoreid, authToken) =>
  await axios.get(process.env.REACT_APP_API + "/gratis/raffle-entries", {
    headers: {
      authToken,
      estoreid,
    },
  });

export const getTopEntries = async (estoreid, authToken, count) =>
  await axios.get(process.env.REACT_APP_API + "/gratis/top-entries/" + count, {
    headers: {
      authToken,
      estoreid,
    },
  });

export const getNotification = async (estoreid, authToken, day) =>
  await axios.get(
    process.env.REACT_APP_API + "/gratis/get-notification/" + day,
    {
      headers: {
        authToken,
        estoreid,
      },
    }
  );

export const getAllUsers = async (
  estoreid,
  authToken,
  sortkey,
  sort,
  currentPage,
  pageSize,
  searchQuery
) =>
  await axios.post(
    process.env.REACT_APP_API + "/gratis/all-users",
    {
      sortkey,
      sort,
      currentPage,
      pageSize,
      searchQuery,
    },
    {
      headers: {
        authToken,
        estoreid,
      },
    }
  );

export const createNewUser = async (estoreid, resellid, values) =>
  await axios.post(
    process.env.REACT_APP_API + "/gratis/user-create/" + resellid,
    values,
    {
      headers: {
        estoreid,
      },
    }
  );

export const sendEmailuser = async (values) =>
  await axios.post(process.env.REACT_APP_API + "/gratis/user-email", values);

export const addToWishlist = async (estoreid, prodid, authToken) =>
  await axios.post(
    process.env.REACT_APP_API + "/gratis/add-wishlist",
    { prodid },
    {
      headers: {
        authToken,
        estoreid,
      },
    }
  );

export const updateUserDetails = async (estoreid, values, authToken) =>
  await axios.put(process.env.REACT_APP_API + "/gratis/user-update", values, {
    headers: {
      authToken,
      estoreid,
    },
  });

export const updateCustomerDetails = async (
  estoreid,
  userid,
  values,
  authToken
) =>
  await axios.put(
    process.env.REACT_APP_API + "/gratis/update-customer/" + userid,
    values,
    {
      headers: {
        authToken,
        estoreid,
      },
    }
  );

export const verifyUserEmail = async (estoreid, values, authToken) =>
  await axios.put(process.env.REACT_APP_API + "/gratis/user-verify", values, {
    headers: {
      authToken,
      estoreid,
    },
  });

export const changePassword = async (estoreid, values, authToken) =>
  await axios.put(
    process.env.REACT_APP_API + "/gratis/change-password",
    values,
    {
      headers: {
        authToken,
        estoreid,
      },
    }
  );

export const resetPassword = async (estoreid, userid, authToken) =>
  await axios.put(
    process.env.REACT_APP_API + "/gratis/reset-password/" + userid,
    {},
    {
      headers: {
        authToken,
        estoreid,
      },
    }
  );

export const forgotPassword = async (estoreid, values) =>
  await axios.put(
    process.env.REACT_APP_API + "/gratis/forgot-password",
    values,
    {
      headers: {
        estoreid,
      },
    }
  );

export const deleteAccountRequest = async (values) =>
  await axios.put(
    process.env.REACT_APP_API + "/gratis/delete-account-request",
    values
  );

export const removeUser = async (estoreid, userid, authToken) =>
  await axios.delete(
    process.env.REACT_APP_API + "/gratis/user-delete/" + userid,
    {
      headers: {
        authToken,
        estoreid,
      },
    }
  );

export const removeAllRaffle = async (estoreid, authToken) =>
  await axios.delete(process.env.REACT_APP_API + "/gratis/delete-all-raffles", {
    headers: {
      authToken,
      estoreid,
    },
  });
