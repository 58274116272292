import axios from "axios";

export const getCategory = async (catid, estoreid, authToken) =>
  await axios.get(process.env.REACT_APP_API + "/gratis/get-category/" + catid, {
    headers: {
      authToken,
      estoreid,
    },
  });

export const getCategories = async (estoreid) =>
  await axios.get(process.env.REACT_APP_API + "/gratis/get-categories", {
    headers: {
      estoreid,
    },
  });

export const addCategory = async (estoreid, values, platform, authToken) =>
  await axios.post(process.env.REACT_APP_API + "/gratis/add-category", values, {
    headers: {
      authToken,
      estoreid,
      platform,
    },
  });

export const updateCategory = async (catid, estoreid, values, authToken) =>
  await axios.put(
    process.env.REACT_APP_API + "/gratis/update-category/" + catid,
    values,
    {
      headers: {
        authToken,
        estoreid,
      },
    }
  );

export const deleteCategory = async (catid, estoreid, authToken) =>
  await axios.delete(
    process.env.REACT_APP_API + "/gratis/remove-category/" + catid,
    {
      headers: {
        authToken,
        estoreid,
      },
    }
  );

export const uploadFileImage = async (image, estore, resellid, authToken) => {
  return await axios.post(
    `${process.env.REACT_APP_CLAVMALL_IMG}/package_function/package${resellid}/paymentimage.php/?estoreId=${estore._id}&resellid=${resellid}`,
    { image },
    {
      headers: {
        authToken,
      },
    }
  );
};

export const removeFileImage = async (
  public_id,
  estore,
  resellid,
  authToken
) => {
  return await axios.post(
    `${process.env.REACT_APP_CLAVMALL_IMG}/package_function/package${resellid}/removeimage.php/?estoreId=${estore._id}&resellid=${resellid}`,
    { public_id },
    {
      headers: {
        authToken,
      },
    }
  );
};

export const checkImageUser = async (public_id, estoreid, authToken) => {
  return await axios.get(
    `${process.env.REACT_APP_API}/gratis/check-image-owner-category/${public_id}`,
    {
      headers: {
        authToken,
        estoreid,
      },
    }
  );
};
