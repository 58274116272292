import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import LoadingToRedirect from "./LoadingToRedirect";

const AdminRoute = ({ children, landing }) => {
  const navigate = useNavigate();

  const user = useSelector((state) => state.user);
  const estoreSet = useSelector((state) => state.estoreSet);

  const sections = [
    { name: "Dashboard", value: "dashboard", show: false },
    { name: "Orders", value: "orders", show: false },
    { name: "Category", value: "category", show: false },
    { name: "Brand", value: "brand", show: false },
    { name: "Payment", value: "payment", show: false },
    { name: "Location", value: "location", show: false },
    { name: "Product", value: "product", show: false },
    { name: "Products", value: "products", show: false },
    { name: "Sales", value: "sales", show: false },
    { name: "Inventory", value: "inventory", show: false },
    { name: "Users", value: "users", show: false },
    { name: "Guide", value: "guide", show: false },
    { name: "Settings", value: "setting", show: false },
  ];

  useEffect(() => {
    const deadline = getDeadline() + 3;
    if (
      (estoreSet &&
        (!estoreSet.upStatus || estoreSet.upStatus === "Pending")) ||
      deadline < 0
    ) {
      navigate(`/${estoreSet.slug}/admin/payupgrade1`);
    } else {
      if (user.role === "admin") {
        loadAllowedPage(
          sections.map((sec) => {
            return { ...sec, show: true };
          })
        );
      }
      if (user.role === "moderator") {
        loadAllowedPage(
          sections.map((sec) => {
            return {
              ...sec,
              show:
                estoreSet &&
                estoreSet.accessibility &&
                estoreSet.accessibility.moderator.includes(sec.value),
            };
          })
        );
      }
      if (user.role === "cashier") {
        loadAllowedPage(
          sections.map((sec) => {
            return {
              ...sec,
              show:
                estoreSet &&
                estoreSet.accessibility &&
                estoreSet.accessibility.cashier.includes(sec.value),
            };
          })
        );
      }
    }
  }, [user]); // eslint-disable-line react-hooks/exhaustive-deps

  const getDeadline = () => {
    const date1 = new Date();
    const date2 = new Date(estoreSet.upEndDate);
    let timeDifference = date2.getTime() - date1.getTime();
    let daysDifference = Math.round(timeDifference / (1000 * 3600 * 24));
    return daysDifference;
  };

  const loadAllowedPage = (showList) => {
    if (
      landing &&
      showList.length > 0 &&
      !showList.find((list) => list.value === landing && list.show)
    ) {
      const adminPage = showList.find((list) => list.show);
      navigate(
        `/${estoreSet.slug}/admin/${
          adminPage.value === "users" ? "manageuser" : adminPage.value
        }`
      );
    }
  };

  return user && ["admin", "moderator", "cashier"].includes(user.role) ? (
    <>{children}</>
  ) : (
    <LoadingToRedirect />
  );
};

export default AdminRoute;
