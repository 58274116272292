import axios from "axios";

export const getRandomProducts = async (estoreid, count) =>
  await axios.get(
    process.env.REACT_APP_API + "/gratis/products/random/" + count,
    {
      headers: {
        estoreid,
      },
    }
  );

export const getProductBySlug = async (estoreid, slug, prodid) =>
  await axios.get(
    process.env.REACT_APP_API +
      "/gratis/get-product-by-slug/" +
      slug +
      "/" +
      prodid,
    {
      headers: {
        estoreid,
      },
    }
  );

export const getProductReviews = async (estoreid, prodid) =>
  await axios.get(
    process.env.REACT_APP_API + "/gratis/get-product-reviews/" + prodid,
    {
      headers: {
        estoreid,
      },
    }
  );

export const getProductById = async (estoreid, prodid, authToken) =>
  await axios.get(
    process.env.REACT_APP_API + "/gratis/get-product-by-id/" + prodid,
    {
      headers: {
        authToken,
        estoreid,
      },
    }
  );

export const getProductByBarcode = async (purpose, estoreid, barcode) =>
  await axios.get(
    process.env.REACT_APP_API + "/gratis/get-product-by-barcode/" + barcode,
    {
      headers: {
        estoreid,
        purpose,
      },
    }
  );

export const getInitProducts = async (estoreid, count, authToken) =>
  await axios.get(process.env.REACT_APP_API + "/gratis/init-product/" + count, {
    headers: {
      authToken,
      estoreid,
    },
  });

export const getInventorySummary = async (estoreid, authToken) =>
  await axios.get(process.env.REACT_APP_API + "/gratis/get-inventory-summary", {
    headers: {
      authToken,
      estoreid,
    },
  });

export const getAdminProducts = async (
  estoreid,
  authToken,
  sortkey,
  sort,
  currentPage,
  pageSize,
  searchQuery,
  category,
  barcode = false,
  sales
) =>
  await axios.post(
    process.env.REACT_APP_API + "/gratis/get-admin-products",
    {
      sortkey,
      sort,
      currentPage,
      pageSize,
      searchQuery,
      category,
      barcode,
      sales,
    },
    {
      headers: {
        authToken,
        estoreid,
      },
    }
  );

export const getBestSellers = async (
  estoreid,
  sortkey,
  sort,
  currentPage,
  pageSize
) =>
  await axios.post(
    process.env.REACT_APP_API + "/gratis/get-best-seller",
    {
      sortkey,
      sort,
      currentPage,
      pageSize,
    },
    {
      headers: {
        estoreid,
      },
    }
  );

export const uploadFileImage = async (image, estore, resellid, authToken) => {
  return await axios.post(
    `${process.env.REACT_APP_CLAVMALL_IMG}/package_function/package${resellid}/addingimage.php/?estoreId=${estore._id}&resellid=${resellid}`,
    { image },
    {
      headers: {
        authToken,
      },
    }
  );
};

export const removeFileImage = async (
  public_id,
  estore,
  resellid,
  authToken
) => {
  return await axios.post(
    `${process.env.REACT_APP_CLAVMALL_IMG}/package_function/package${resellid}/removeimage.php/?estoreId=${estore._id}&resellid=${resellid}`,
    { public_id },
    {
      headers: {
        authToken,
      },
    }
  );
};

export const uploadRateImage = async (image, estore, resellid, authToken) => {
  return await axios.post(
    `${process.env.REACT_APP_CLAVMALL_IMG}/package_function/package${resellid}/addrateimage.php/?estoreId=${estore._id}&resellid=${resellid}`,
    { image },
    {
      headers: {
        authToken,
      },
    }
  );
};

export const checkImageUser = async (public_id, estoreid, authToken) => {
  return await axios.get(
    `${process.env.REACT_APP_API}/gratis/check-image-owner-product/${public_id}`,
    {
      headers: {
        authToken,
        estoreid,
      },
    }
  );
};

export const getWaitingProducts = async (estoreid, authToken) =>
  await axios.get(process.env.REACT_APP_API + "/gratis/get-waiting-products", {
    headers: {
      authToken,
      estoreid,
    },
  });

export const addProduct = async (estoreid, values, platform, authToken) =>
  await axios.post(process.env.REACT_APP_API + "/gratis/add-product", values, {
    headers: {
      authToken,
      estoreid,
      platform,
    },
  });

export const getSearchedProducts = async (estoreid, values) =>
  await axios.post(
    process.env.REACT_APP_API + "/gratis/search-product",
    values,
    {
      headers: {
        estoreid,
      },
    }
  );

export const submitRating = async (estoreid, values, authToken) =>
  await axios.post(
    process.env.REACT_APP_API + "/gratis/submit-rating",
    values,
    {
      headers: {
        estoreid,
        authToken,
      },
    }
  );

export const handleProductUpdate = async (
  estoreid,
  prodid,
  values,
  authToken
) =>
  await axios.put(
    process.env.REACT_APP_API + "/gratis/update-product/" + prodid,
    values,
    {
      headers: {
        authToken,
        estoreid,
      },
    }
  );

export const receiveProducts = async (estoreid, products, authToken) =>
  await axios.put(
    process.env.REACT_APP_API + "/gratis/receive-product",
    products,
    {
      headers: {
        authToken,
        estoreid,
      },
    }
  );

export const importProducts = async (estoreid, products, authToken) =>
  await axios.put(
    process.env.REACT_APP_API + "/gratis/import-products",
    {
      products,
    },
    {
      headers: {
        authToken,
        estoreid,
      },
    }
  );

export const updateWaitingProd = async (estoreid, waiting, authToken) =>
  await axios.put(
    process.env.REACT_APP_API + "/gratis/update-waiting-product",
    waiting,
    {
      headers: {
        authToken,
        estoreid,
      },
    }
  );

export const deleteProduct = async (estoreid, prodid, authToken) =>
  await axios.delete(
    process.env.REACT_APP_API + "/gratis/delete-product/" + prodid,
    {
      headers: {
        authToken,
        estoreid,
      },
    }
  );

export const deleteWaitingProd = async (estoreid, waitid, authToken) =>
  await axios.delete(
    process.env.REACT_APP_API + "/gratis/delete-waiting-product/" + waitid,
    {
      headers: {
        authToken,
        estoreid,
      },
    }
  );
