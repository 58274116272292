import { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import NoStore from "../../pages/NoStore";

const NoUserRoute = ({ children, setSlug, estore }) => {
  const navigate = useNavigate();
  const { slug } = useParams();

  const user = useSelector((state) => state.user);
  const estoreSet = useSelector((state) => state.estoreSet);

  useEffect(() => {
    const deadline = getDeadline() + 3;
    if (slug) {
      setSlug(slug);
    }
    if (
      (user &&
        user.token &&
        estoreSet &&
        (!estoreSet.upStatus || estoreSet.upStatus === "Pending")) ||
      deadline < 0
    ) {
      navigate(`/${estoreSet.slug}/admin/payupgrade1`);
    }
  }, [slug]); // eslint-disable-line react-hooks/exhaustive-deps

  const getDeadline = () => {
    const date1 = new Date();
    const date2 = new Date(estoreSet.upEndDate);
    let timeDifference = date2.getTime() - date1.getTime();
    let daysDifference = Math.round(timeDifference / (1000 * 3600 * 24));
    return daysDifference;
  };

  return estore && estore._id ? <>{children}</> : <NoStore />;
};

export default NoUserRoute;
