import axios from "axios";

export const getEstore = async (slug, resellid) =>
  await axios.get(process.env.REACT_APP_API + "/gratis/estore/" + slug, {
    headers: {
      resellid,
    },
  });

export const getDefaultEstore = async (resellid) =>
  await axios.get(process.env.REACT_APP_API + "/gratis/default-estore", {
    headers: {
      resellid,
    },
  });

export const getPackage = async (id) =>
  await axios.get(process.env.REACT_APP_API + "/gratis/get-package/" + id);

export const getPackages = async (estoreid, defaultPackage) =>
  await axios.get(
    process.env.REACT_APP_API + "/gratis/get-packages/" + defaultPackage,
    {
      headers: {
        estoreid,
      },
    }
  );

export const getEstoreCounters = async (estoreid) =>
  await axios.get(
    process.env.REACT_APP_API + "/gratis/estore-counters/" + estoreid
  );

export const checkDomainAvail = async (domain) => {
  const options = {
    method: "GET",
    url: "https://domainr.p.rapidapi.com/v2/status",
    params: {
      domain,
    },
    headers: {
      "x-rapidapi-key": "86add06d68msh211b20d9d8ebe05p18600fjsn5c70be06a1ec",
      "x-rapidapi-host": "domainr.p.rapidapi.com",
    },
  };
  try {
    return await axios.request(options);
  } catch (error) {
    return { err: "Error checking domain availability" };
  }
};

export const getDedicatedEstores = async () =>
  await axios.get(process.env.REACT_APP_API + "/gratis/dedicated-estores");

export const updateEstore = async (estoreid, values, authToken) =>
  await axios.post(
    process.env.REACT_APP_API + "/gratis/estore-update",
    values,
    {
      headers: {
        authToken,
        estoreid,
      },
    }
  );

export const createEstore = async (values, resellid) =>
  await axios.post(
    process.env.REACT_APP_API + "/gratis/estore-create/" + resellid,
    values
  );

export const addNewEstore = async (estoreid, values, resellid, authToken) =>
  await axios.post(
    process.env.REACT_APP_API + "/gratis/add-new-estore/" + resellid,
    values,
    {
      headers: {
        authToken,
        estoreid,
      },
    }
  );

export const uploadSettingImage = async (
  image,
  estore,
  resellid,
  authToken
) => {
  return await axios.post(
    `${process.env.REACT_APP_CLAVMALL_IMG}/package_function/package${resellid}/settingimage.php/?estoreId=${estore._id}&resellid=${resellid}`,
    { image },
    {
      headers: {
        authToken,
      },
    }
  );
};

export const copyingEstore = async (estoreid, values, resellid, authToken) =>
  await axios.post(
    process.env.REACT_APP_API + "/gratis/copying-estore/" + resellid,
    values,
    {
      headers: {
        authToken,
        estoreid,
      },
    }
  );

export const updateEstoreCounters = async (estoreid, values) =>
  await axios.put(
    process.env.REACT_APP_API + "/gratis/update-estore-counters",
    values,
    {
      headers: {
        estoreid,
      },
    }
  );

export const updateEstoresDefault = async (estoreid) =>
  await axios.put(
    process.env.REACT_APP_API + "/gratis/update-estores-default",
    { estoreid }
  );

export const deletingEstore = async (estoreid, deleteid, authToken) =>
  await axios.delete(
    process.env.REACT_APP_API + "/gratis/delete-estore/" + deleteid,
    {
      headers: {
        authToken,
        estoreid,
      },
    }
  );
